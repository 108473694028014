import { FC, Fragment } from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../img/Somaiya_Logo.png";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
  },
  name: {
    marginBottom: "0.75rem",
    marginTop: "1rem",
    fontWeight: "bold",
    fontSize: "2rem",
  },
  projectName: {
    marginTop: "2.5rem",
    color: "#911717",
    marginBottom: "0.5rem",
    fontWeight: "bold",
  },
  department: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    marginTop: "0.5rem",
  },
  footer: {
    backgroundColor: "#231F20",
    display: "flex",
    justifyContent: "space-around",
    padding: "1rem 0.5rem",
    color: "#FFF",
    minWidth: "100%",
    bottom: 0,
    marginTop: "3rem",
    height: "fit-content",
    marginBottom: 0,
    fontSize: 18,
    "& a": {
      textDecoration: "none",
      color: "#FFF",
    },
  },
}));

const Homepage: FC = () => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.root}>
        <img src={logo} />
        <Typography component="h1" variant="h4" className={classes.name}>
          K. J. Somaiya Institute of Engineering and Information Technology,
          Sion, Mumbai
        </Typography>
        <Typography component="h2" variant="h6">
          An Autonomous Institute Permanently Affiliated to the University of
          Mumbai
        </Typography>
        <Typography component="h2" variant="h6">
          Accredited with 'A' Grade by NAAC (3.21 CGPA), Approved by AICTE, New
          Delhi.
        </Typography>
        <Typography component="h3" variant="h3" className={classes.projectName}>
          Exam Question Paper Upload System
        </Typography>
        <Typography component="h4" variant="h5" className={classes.department}>
          Developed by Department of Computer Engineering
        </Typography>
      </div>
      <footer className={classes.footer}>
        <a href="https://kjsieit.somaiya.edu/en" target="_blank">
          KJSIEIT
        </a>
        <div>
          <Link to="/support">Support &nbsp;|&nbsp;&nbsp;</Link>
          <Link to="/about-us">About Us</Link>
        </div>
        <a href="https://somaiya.edu/en" target="_blank">
          Somaiya Vidyavihar
        </a>
      </footer>
    </div>
  );
};
export default Homepage;
