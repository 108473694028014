import { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

const useStyles = makeStyles({
  container2: {
    fontFamily: "Segoe UI",
    padding: "5rem",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    fontSize: "1.5rem",
  },
  "& h1": {
    fontSize: "2em",
    marginBlockStart: "0.67em",
    marginBlockEnd: "0.67em",
    marginInlineStart: "0px",
    marginInlineEnd: "0px",
    fontWeight: "bold",
    textAlign: "center",
  },
  rowContent: {
    marginTop: 15,
    marginBottom: 10,
    padding: "20 0",
    "& h3": {
      display: "block",
      fontSize: "1.17em",
      marginBlockStart: "1em",
      marginBlockEnd: "1em",
      marginInlineStart: 0,
      marginInlineEnd: 0,
      fontWeight: "bold",
    },
    "& p": {
      display: "block",
      marginBlockStart: "1em",
      marginBlockEnd: "1em",
      marginInlineStart: 0,
      marginInlineEnd: 0,
      fontSize: 20,
    },
    "& ul": {
      fontSize: 20,
      paddingLeft: 0,
    },
  },
  team: {
    listStyleType: "none",
    "& li": {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid #000",
      // padding: "0.75rem 0",
      alignItems: "center",
    },
    "& li div": {
      display: "flex",
      alignItems: "center",
    },
    "& li a:first-of-type .MuiSvgIcon-root": {
      color: "#000",
      fontSize: 35,
      marginRight: "0.2rem",
    },
    "& li a:last-of-type .MuiSvgIcon-root": {
      color: "#000",
      fontSize: 45,
    },
  },
});

const AboutUs: FC = () => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.container2}>
        <div
          className="About-Us-Title-h1"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1 style={{ textDecoration: "underline" }}>
            Exam Paper Upload System
          </h1>
          <h1>About Us</h1>
        </div>
        <div className={classes.rowContent}>
          <div>
            <h3>About KJSIEIT</h3>
          </div>
          <div>
            <p>
              K. J. Somaiya Institute of Engineering and Information Technology
              (KJSIEIT) is an autonomous engineering institute recognized by
              UGC, approved by AICTE & DTE, and is permanently affiliated to the
              University of Mumbai. KJSIEIT offers UG programs in Computer
              Engineering, Information Technology, Artificial Intelligence &
              Data Science, Electronics & Telecommunication Engineering, and PG
              program in Artificial Intelligence.
            </p>
            <div>
              <p>
                <ul>
                  <li>
                    KJSIEIT is accredited with ‘A’ Grade (CGPA 3.21) by NAAC for
                    5 Years and its two programs are re-accredited by NBA.
                  </li>
                  <li>
                    In Indian Rankings 2020, KJSIEIT has been placed in Rank
                    Band 251-300 by National Institutional Ranking Framework
                    (NIRF).
                  </li>
                  <li>
                    In Atal Ranking of Institutions on Innovation Achievements
                    (ARIIA) 2020, KJSIEIT is placed in Rank Band ‘B’ indicating
                    a rank in Top 50 participating Private Institutions across
                    India.
                  </li>
                  <li>
                    KJSIEIT was presented Best College Award by University of
                    Mumbai (Urban Region), ISTE (Maharashtra and Goa Section)
                    and CSI (Mumbai Region).
                  </li>
                  <li>
                    In AICTE-CII Survey of Industry-Linked Technical Institutes,
                    KJSIEIT is placed in Platinum Category for 2020, 2019 & 2018
                    and in Gold Category for 2017 & 2016.
                  </li>
                  <li>
                    KJSIEIT got 297th ALL INDIA RANK in AICTE Internshala’s
                    Internship Ranking 2021 among 2000+ participating colleges
                    across India.
                  </li>
                  <li>
                    Institutes PocketQube standard satellite proposal selected
                    by ISRO for launching experimental satellite platform aboard
                    PS4-OP program.
                  </li>
                  <li>
                    KJSIEIT received 3rd, 5th, 8th, 9th and 12th All India
                    Ranking in Asia Pacific Broadcasting Union (ABU) National
                    Robotic Contest (Robocon) for the year 2020, 2019, 2018 2017
                    and 2015 respectively.
                  </li>
                  <li>
                    KJSIEIT bagged Best Academic Affiliate Institute by The
                    Institute of Engineering & Technology (IET)
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className={classes.rowContent}>
          <div>
            <h3>About Department of Computer Engineering</h3>
          </div>
          <div>
            <p>
              The department of Computer Engineering was established in the year
              2001 to impart quality education with UG B. Tech (Computer
              Engineering) intake of 120 and PG, M. Tech (Artificial
              Intelligence)intake of 18.
            </p>
            <div>
              <p>
                <ul>
                  <li>
                    The department is accredited by NBA in 2018 and reaccredited
                    in 2021.
                  </li>
                  <li>
                    The department has highly qualified, determined and
                    dedicated teaching faculty who prepare students to excel in
                    the field of Computer Engineering.
                  </li>
                  <li>The state-of-the-art infrastructure.</li>
                  <li>
                    Project Based, Skill based, Activity based and Technology
                    based teaching learning process is the strength of the
                    department.
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className={classes.rowContent}>
          <div>
            <h3>Vision of The Department</h3>
          </div>
          <div>
            <p>
              To be an excellent centre of learning, imparting quality education
              and creating computer competent professionals to serve the society
              at large.
            </p>
          </div>
        </div>
        <div className={classes.rowContent}>
          <div>
            <h3>Mission of The Department</h3>
          </div>
          <div>
            <div>
              <ul>
                <li>
                  To provide quality education required to shape skilled
                  computer engineers.
                </li>
                <li>
                  To promote scientific temper and research culture through
                  interdisciplinary and industrial collaboration.
                </li>
                <li>
                  To prepare industry ready professionals, having ethical values
                  and social commitment.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={classes.rowContent}>
          <div>
            <h3>Department Program Educational Objectives (PEOs)</h3>
          </div>
          <div>
            <div>
              <ul>
                <li>
                  Graduates will be capable of analysing and solving problems
                  for diverse applications.
                </li>
                <li>
                  Graduates will be engaging themselves in lifelong learning and
                  professional development to adapt to the dynamic work
                  environment.
                </li>
                <li>
                  Graduates will be able to exhibit a high level of
                  professionalism and work collaboratively at their workplace.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={classes.rowContent}>
          <div>
            <h3>Department Program Specific Outcomes (PSOs)</h3>
          </div>
          <div>
            <div>
              <ul>
                <li>
                  Graduates of this program will be able to apply software
                  engineering practices in software project development.
                </li>
                <li>
                  Graduates of this program will be able to use the knowledge of
                  algorithm, data structure, database,networking to develop
                  solution in various domains.
                </li>
                <li>
                  Graduates of this program will be able to analyse the working
                  and design of computer system with relevant system software.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={classes.rowContent}>
          <div>
            <h3>Exam Paper Upload System (EPUS)</h3>
          </div>
          <div>
            <p>
              <strong>Exam Paper Upload System (EPUS)</strong> is an online
              application which comprises of three sections: Convener, Exam Cell
              Admin and Super Login. This platform is used to upload a set of
              three question papers set by Internal and External Examiners
              through Convener login. Using this platform, Exam section of
              institute can manage all question papers for various subjects in
              each department and also set exam dates for all papers. Using this
              application, main question paper on the day of examination can be
              downloaded using Super Login by selecting one paper randomly out
              of three question paper set uploaded by Convenor.
            </p>
            <p>
              Exam Paper Upload System (EPUS) automates the existing manual
              process. This application is very useful for exam section, and the
              institute for managing all exam related activities in an efficient
              way. It serves as an alternative to tedious manual process which
              can cause security issues by mishandling of question papers.
            </p>
          </div>
        </div>
        <div className={classes.rowContent}>
          <div>
            <h3>
              Developed By: Students of Department of Computer Engineering
            </h3>
          </div>
          <div>
            <div>
              <ul className={classes.team}>
                <li>
                  Mitanshu Reshamwala
                  <div>
                    <a href="https://github.com/MitanshuShaBa">
                      <GitHubIcon />
                    </a>
                    <a href="https://www.linkedin.com/in/rmitanshu-0111/">
                      <LinkedInIcon />
                    </a>
                  </div>
                </li>
                <li>
                  Shivam Panchal
                  <div>
                    <a href="https://github.com/GodWin1100">
                      <GitHubIcon />
                    </a>
                    <a href="https://www.linkedin.com/in/shivam-panchal-godwin1100/">
                      <LinkedInIcon />
                    </a>
                  </div>
                </li>
                <li>
                  Taha Lokat
                  <div>
                    <a href="https://github.com/Taha-001">
                      <GitHubIcon />
                    </a>
                    <a href="https://www.linkedin.com/in/taha-lokat-6559a8223/">
                      <LinkedInIcon />
                    </a>
                  </div>
                </li>
                <li>
                  Aryan Singh
                  <div>
                    <a href="https://github.com/Durgvanshi">
                      <GitHubIcon />
                    </a>
                    <a href="https://www.linkedin.com/in/aryan-singh-8253b1b0/">
                      <LinkedInIcon />
                    </a>
                  </div>
                </li>
                <li>
                  Shruti Shinde
                  <div>
                    <a href="https://github.com/ShrutiShinde418">
                      <GitHubIcon />
                    </a>
                    <a href="https://linkedin.com/in/shruti-shinde-5589461a4">
                      <LinkedInIcon />
                    </a>
                  </div>
                </li>
                <li>
                  Pranjal Sancheti
                  <div>
                    <a href="https://github.com/Pranjal-Sancheti">
                      <GitHubIcon />
                    </a>
                    <a href="https://www.linkedin.com/in/pranjalsancheti">
                      <LinkedInIcon />
                    </a>
                  </div>
                </li>
                <li>
                  Rishita Yadav
                  <div>
                    <a href="https://github.com/rishita0906">
                      <GitHubIcon />
                    </a>
                    <a href="https://www.linkedin.com/in/rishita-yadav-8516311b6">
                      <LinkedInIcon />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={classes.rowContent}>
          <div>
            <h3>Under the Guidance of:</h3>
          </div>
          <div>
            <p>Dr. Sarita Ambadekar (HOD, Computer Engineering)</p>
            <p>Prof. Pradnya Patil</p>
            <p>Prof. Shyamal Virnodkar</p>
          </div>
        </div>
        <div className={classes.rowContent}>
          <div>
            <h3>For More Details, Contact us on:</h3>
          </div>
          <div>
            <p>epus.tech@somaiya.edu</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
