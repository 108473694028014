export const initialState = {
  token: sessionStorage.getItem("login") || null,
  email: null,
  role: null,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_TOKEN":
      return {
        ...state,
        token: action.token,
      };
    case "SET_EMAIL":
      return {
        ...state,
        email: action.email,
      };
    case "SET_ROLE":
      return {
        ...state,
        role: action.role,
      };
    default:
      return state;
  }
}

export default reducer;
