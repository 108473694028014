import { useParams, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Container,
  makeStyles,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(8),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
    backgroundColor: "#911717",
    "&:hover": {
      backgroundColor: "#911717",
      boxShadow: "1px 2px 5px black",
    },
  },
}));

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#911717",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#911717",
      },
    },
  },
})(TextField);

const ChangePassword = () => {
  const { token } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/change-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, password }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.err) {
          alert(data.err);
        } else {
          alert(`Password changed successfully`);
        }
        history.push("/login");
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography component="h1" variant="h5">
            Enter your new password
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <CssTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Submit
            </Button>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ChangePassword;
