import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  CardContent,
  Container,
  createStyles,
  IconButton,
  Modal,
  Theme,
  Typography,
  withStyles,
  Card,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { pdf } from "@react-pdf/renderer";
import PDFPreview from "../preview/PDFPreview";
import { lighten } from "@material-ui/core";
import { TableSortLabel } from "@material-ui/core";
// import SearchBar from "material-ui-search-bar";
import { TextField } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import GetOTP from "./GetOTP";
import romanize from "../utils/romanize";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
// import SyncIcon from "@material-ui/icons/Sync";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloseIcon from "@material-ui/icons/Close";
import RenderInstance from "../upload/RenderInstance";
import { storage } from "../../firebase";
import {
  Select,
  MenuItem,
  InputLabel,
  Input,
  FormControl,
} from "@material-ui/core";
//import { v4 as uuidv4 } from "uuid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import zIndex from "@material-ui/core/styles/zIndex";
import Signup from "../signup_admin";
import { extname } from "path";
const axios = require("axios");

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "White",
    [theme.breakpoints.up("md")]: {
      width: "40vw",
    },
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    minWidth: 650,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: lighten("#911717", 0.15),
      //color: theme.palette.common.white,
      fontSize: 16,
      zIndex: 0,
    },
    body: {
      fontSize: 14,
      zIndex: 100,
    },
  })
)(TableCell);

const StyledTableSortLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "white",
      "&:hover": {
        color: "white",
      },
      "&$active": {
        color: "white",
      },
    },
    active: {},
    icon: {
      color: "inherit !important",
    },
  })
)(TableSortLabel);

const SuperAdmin = () => {
  const classes = useStyles();
  const [appointments, setAppointments] = useState([]);
  const [paginated, setPaginated] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState({ otp: "", hash: "", email: "", action: "" });
  const [appointmentID, setAppointmentID] = useState("");
  const [change, setChange] = useState(true);
  const [startDate, setStartDate] = useState(new Map());
  const [compareDate, setCompareDate] = useState(new Map());
  const [page, setPage] = useState(0);
  const [appointmentIT, setAppointmentIT] = useState([]);
  const [teacher_id_map, SetTeacher_id_map] = useState({});
  const [sem, setSem] = useState("");
  const [dept_name, setdept_name] = useState("");
  let serial_no = 0;
  const [previewPDF, setPreviewPDF] = useState<any>({
    index: null,
    data: [],
  });
  const uploadRef = useRef();

  // eslint-disable-next-line
  // eslint-disable-next-line
  const [dept, setdept] = useState([1, 2]);
  const [selectedDate, setSelectedDate] = useState(
    new Date("2014-08-18T21:11:54")
  );
  // eslint-disable-next-line
  const [SemesterFilter, setSemesterFilter] = useState([]);

  const deptList = [
    "Computer Engineering",
    "Information Technology",
    "Electronics and Telecommunication Engineering",
    "Artificial Intelligence and Data Science",
  ];
  const SemesterList = [1, 2, 3, 4, 5, 6, 7, 8];
  // OTP
  const handleOpenOTP = async (_id) => {
    setOpen(true);
    setAppointmentID(_id);
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/otp/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: "epus.tech@somaiya.edu", id: _id }),
    });
    const { email, hash } = await res.json();
    setOtp((prevOtp) => {
      return { ...prevOtp, hash, email };
    });
  };
  const handleCloseOTP = () => {
    setOpen(false);
  };

  const History = useHistory();

  // fetch appointments from our database
  const fetchAppointments = async () => {
    const apps1 = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/appointments`,
      {
        method: "GET",
      }
    );
    let data = await apps1.json();
    let date = new Date().toISOString().slice(0, 10);
    console.log(date);
    data = data.filter((appointment) => {
      console.log(appointment.examdate);
      return appointment.examdate === date;
    });

    setAppointments(data);
    setRows(data);
    // const Deptset = new Set();
    // data.map(({ department }) => Deptset.add(department));
    // const tempDeptList: string[] = [];
    // Deptset.forEach((value: string) => tempDeptList.push(value));
    // setDeptList(tempDeptList);

    // const ClassSet = new Set();
    // data.map(({ subject }) => ClassSet.add(subject));
    // const tempClassList: string[] = [];
    // ClassSet.forEach((value: string) => tempClassList.push(value));
    // setClassList(tempClassList);

    // const SemesterSet = new Set();
    // data.map(({ semester }) => SemesterSet.add(semester));
    // const tempSemesterList: string[] = [];
    // SemesterSet.forEach((value: string) => tempSemesterList.push(value));
    // setSemesterList(tempSemesterList);
  };
  // console.log(deptList);
  // console.log(ClassList);
  // console.log(SemesterList);
  // console.log(appointments);

  //Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const emptyRows = 10 - Math.min(10, rows.length - page * 10);

  // Use Effects
  useEffect(() => {
    const token = sessionStorage.getItem("login");
    if (token === null) {
      History.push("/login?redirect=reports");
    }
    fetchAppointments();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (otp.otp !== "" && open) {
      if (otp.action === "download") {
        downloadPaper();
      } else if (otp.action === "delete") {
        deletePaper();
      }
    }
    // eslint-disable-next-line
  }, [otp, open]);

  const downloadPDF = (fileName, href) => {
    console.log(href);
    fetch(`${href}`, {
      method: "GET",
    })
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = fileName; // the filename you want
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };

  const verifyOTP = async () => {
    console.log(otp);
    const resVerified = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/otp/verify`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(otp),
      }
    );
    const verified = await resVerified.json();
    return verified;
  };

  const downloadPaper = async () => {
    const verified = await verifyOTP();
    if (verified.msg === "Verified") {
      console.log(appointmentID);
      fetch(`${process.env.REACT_APP_BACKEND_URL}/paper?id=${appointmentID}`)
        .then((res) => res.json())
        .then(
          ({ appointment: { subject, semester, department }, questions }) => {
            const fileName = `${subject}-${department} ${semester}.pdf`;

            downloadPDF(fileName, questions.pdfURL);

            setOpen(false);
          }
        );
    } else {
      alert(verified.err);
    }
    setOtp({ otp: "", hash: "", email: "", action: "" });
  };

  const deletePaper = async () => {
    const verified = await verifyOTP();
    if (verified.msg === "Verified") {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/paper?id=${appointmentID}`, {
        method: "DELETE",
      })
        .then(() => {
          window.location.reload();
        })
        .catch((err) => console.log(err));
    } else {
      alert(verified.err);
    }
    setOtp({ otp: "", hash: "", email: "", action: "" });
  };

  const handleDeptFilter = async (e) => {
    await setdept_name(e.target.value);
    let filtered;
    filtered = appointments.filter((app) =>
      app.department.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setRows([...filtered]);
    console.log(dept_name);
  };

  const handleSemFilter = async (e) => {
    await setSem(e.target.value.toString());
    let filtered;
    if (dept_name === "") {
      alert(`Department not selected`);
      filtered = appointments.filter((app) =>
        app.semester
          .toString()
          .toLowerCase()
          .includes(e.target.value.toString().toLowerCase())
      );
    } else {
      filtered = appointments.filter(
        (app) =>
          app.department.toLowerCase().includes(dept_name.toLowerCase()) &&
          app.semester
            .toString()
            .toLowerCase()
            .includes(e.target.value.toString().toLowerCase())
      );
    }
    setRows([...filtered]);
  };

  const createNewAdmin = () => {
    // eslint-disable-next-line
    History.push("/signup");
  };

  const goToPanel = () => {
    History.push("/panel");
  };

  const deleteAppointments = () => {};

  return (
    <Container maxWidth="lg">
      <GetOTP open={open} handleClose={handleCloseOTP} handleOTP={setOtp} />
      {sessionStorage.getItem("role") && (
        <Typography
          variant="h4"
          style={{
            marginTop: "2vh",
            textAlign: "center",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          Super Admin View
        </Typography>
      )}
      <br></br>
      {sessionStorage.getItem("role") && (
        <Typography
          variant="h5"
          style={{ marginTop: "1vh", textAlign: "left", fontWeight: "bold" }}
        >
          {sessionStorage.getItem("role") === "teacher"
            ? "Name : " + sessionStorage.getItem("name")
            : sessionStorage.getItem("role") === "admin"
            ? "Name : " + sessionStorage.getItem("name")
            : "Name : " + sessionStorage.getItem("name")}{" "}
        </Typography>
      )}
      {sessionStorage.getItem("role") && (
        <Typography
          variant="h5"
          style={{ textAlign: "left", fontWeight: "bold", marginTop: "1vh" }}
        >
          {sessionStorage.getItem("role") === "teacher"
            ? "Email : " + sessionStorage.getItem("email")
            : sessionStorage.getItem("role") === "admin"
            ? "Email : " + sessionStorage.getItem("email")
            : "Email : " + sessionStorage.getItem("email")}{" "}
        </Typography>
      )}
      <br />
      <br></br>
      {sessionStorage.getItem("role") === "admin" && (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ margin: "5px", width: "200px" }}>
            <FormControl variant="filled" fullWidth={true}>
              <InputLabel id="demo-simple-select-outlined-label">
                Department
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Department"
                value={dept_name}
                onChange={(e) => {
                  handleDeptFilter(e);
                }}
              >
                {deptList.map((o) => (
                  <MenuItem value={o}>{o}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ margin: "5px", width: "200px" }}>
            <FormControl variant="filled" fullWidth={true}>
              <InputLabel id="demo-simple-select-filled-label">
                Semester
              </InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-filled-outlined"
                label="Sem"
                value={sem}
                onChange={(e) => {
                  handleSemFilter(e);
                }}
              >
                {SemesterList.map((o) => (
                  <MenuItem value={o}>{o}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <IconButton
            onClick={() => {
              setdept_name("");
              setSem("");
              setRows([...appointments]);
            }}
          >
            <CancelIcon />
          </IconButton>
        </div>
      )}
      <br />

      {sessionStorage.getItem("role") !== "teacher" && (
        <div style={{ textAlign: "right", marginTop: "40px" }}>
          <Button
            style={{ backgroundColor: "#911717", color: "white" }}
            variant="contained"
            onClick={() => createNewAdmin()}
          >
            New Admin
          </Button>
          <Button
            style={{
              backgroundColor: "#911717",
              color: "white",
              marginLeft: "50px",
            }}
            variant="contained"
            onClick={() => goToPanel()}
          >
            Manage Admins
          </Button>
        </div>
      )}
      <br></br>
      <TableContainer component={Paper}>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {[
                { key: "serial_no", display: "SR No." },
                { key: "level", display: "Level" },
                { key: "department", display: "Department" },
                //{ key: "examTitle", display: "Exam Title" },
                { key: "semester", display: "Semester" },
                { key: "subjectCode", display: "Subject Code" },
                { key: "subject", display: "Subject Name" },
                { key: "syllabusRevision", display: "Syllabus Revision" },
                { key: "teacher", display: "Convener" },
                { key: "submitted", display: "Paper upload status" },
                { key: "download", display: "Download Paper" },
              ].map(({ key, display }, index) => {
                return (
                  <StyledTableCell align="right" key={index}>
                    <StyledTableSortLabel
                      active={orderBy === key}
                      //@ts-ignore
                      // direction={orderBy === key ? order : "asc"}
                      // onClick={(e) => {
                      //   handleRequestSort(e, key);
                      // }}
                    >
                      {display}
                    </StyledTableSortLabel>
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * 10, page * 10 + 10)
              .map(
                (
                  {
                    level,
                    year,
                    department,
                    semester,
                    subject,
                    subjectCode,
                    syllabusRevision,
                    examdate,
                    teacher,
                    submitted,
                    _id,
                  },
                  index
                ) => (
                  <TableRow hover key={index}>
                    <StyledTableCell align="center">
                      {(serial_no += 1)}
                    </StyledTableCell>
                    <StyledTableCell align="right">{level}</StyledTableCell>
                    <StyledTableCell align="left">{department}</StyledTableCell>
                    <StyledTableCell align="center">
                      {romanize(semester)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {subjectCode}
                    </StyledTableCell>
                    <StyledTableCell align="left">{subject}</StyledTableCell>

                    <StyledTableCell align="left">
                      {syllabusRevision}
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      {teacher.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {submitted ? <CheckCircleIcon /> : <CancelIcon />}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IconButton
                        disabled={!submitted}
                        onClick={() => {
                          handleOpenOTP(_id);
                          setOtp((prevState) => {
                            return { ...prevState, action: "download" };
                          });
                        }}
                      >
                        <GetAppIcon />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        rowsPerPage={10}
        component="div"
        count={200}
        page={page}
        onPageChange={handleChangePage}
      />
    </Container>
  );
};

export default SuperAdmin;
