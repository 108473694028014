import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  CardContent,
  Container,
  createStyles,
  IconButton,
  Modal,
  Theme,
  Typography,
  withStyles,
  Card,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { pdf } from "@react-pdf/renderer";
import PDFPreview from "../preview/PDFPreview";
import { lighten } from "@material-ui/core";
import { TableSortLabel } from "@material-ui/core";
// import SearchBar from "material-ui-search-bar";
import { TextField } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import GetOTP from "./GetOTP";
import romanize from "../utils/romanize";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
// import SyncIcon from "@material-ui/icons/Sync";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloseIcon from "@material-ui/icons/Close";
import RenderInstance from "../upload/RenderInstance";
import { storage } from "../../firebase";
import {
  Select,
  MenuItem,
  InputLabel,
  Input,
  FormControl,
} from "@material-ui/core";
import SuperAdmin from "./SuperAdmin";
import AdminConvener from "./AdminConvener";
//import { v4 as uuidv4 } from "uuid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import zIndex from "@material-ui/core/styles/zIndex";
import Signup from "../signup_admin";
import { extname } from "path";
const axios = require("axios");

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "White",
    [theme.breakpoints.up("md")]: {
      width: "40vw",
    },
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    minWidth: 650,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: lighten("#911717", 0.15),
      //color: theme.palette.common.white,
      fontSize: 16,
      zIndex: 0,
    },
    body: {
      fontSize: 14,
      zIndex: 100,
    },
  })
)(TableCell);

const StyledTableSortLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "white",
      "&:hover": {
        color: "white",
      },
      "&$active": {
        color: "white",
      },
    },
    active: {},
    icon: {
      color: "inherit !important",
    },
  })
)(TableSortLabel);

const Reports = () => {
  if (sessionStorage.getItem("role") === "admin") return <AdminConvener />;
  else if (sessionStorage.getItem("role") === "teacher")
    return <AdminConvener />;
  else return <SuperAdmin />;
};

export default Reports;
