import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";

const GetOTP = ({ open, handleClose, handleOTP }) => {
  const [otp, setOtp] = useState<number | "">("");

  const submitOtp = () => {
    handleOTP((prevState) => {
      return { ...prevState, otp };
    });
  };

  useEffect(() => {
    !open && setOtp("");
  }, [open]);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">OTP</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the OTP received on your email
          </DialogContentText>
          <TextField
            value={otp}
            onChange={(e) => {
              const value = e.target.value;
              setOtp(value === "" ? value : parseInt(value));
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                submitOtp();
              }
            }}
            autoFocus
            margin="dense"
            id="otp"
            label="OTP"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={submitOtp} color="primary">
            Download Paper
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GetOTP;
