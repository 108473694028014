import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import "date-fns";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import DateFnsUtils from "@date-io/date-fns";
import Tooltip from "@material-ui/core/Tooltip";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  CardContent,
  Container,
  createStyles,
  IconButton,
  Modal,
  Theme,
  Typography,
  withStyles,
  Card,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { lighten } from "@material-ui/core";
import { TableSortLabel } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import GetOTP from "./GetOTP";
import romanize from "../utils/romanize";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
// import SyncIcon from "@material-ui/icons/Sync";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import RenderInstance from "../upload/RenderInstance";
import { storage } from "../../firebase";
import {
  Select,
  MenuItem,
  InputLabel,
  Input,
  FormControl,
} from "@material-ui/core";

//import { v4 as uuidv4 } from "uuid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import zIndex from "@material-ui/core/styles/zIndex";
import Signup from "../signup_admin";
import { extname } from "path";
import { couldStartTrivia } from "typescript";
const axios = require("axios");

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "White",
    [theme.breakpoints.up("md")]: {
      width: "40vw",
    },
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    minWidth: 650,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  circularProgress: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiCircularProgress-colorPrimary": {
      color: "#911717",
    },
  },
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: lighten("#911717", 0.15),
      //color: theme.palette.common.white,
      fontSize: 16,
      zIndex: 0,
    },
    body: {
      fontSize: 14,
      zIndex: 100,
    },
  })
)(TableCell);

const StyledTableSortLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "white",
      "&:hover": {
        color: "white",
      },
      "&$active": {
        color: "white",
      },
    },
    active: {},
    icon: {
      color: "inherit !important",
    },
  })
)(TableSortLabel);

const AdminConvener = () => {
  const classes = useStyles();
  const [appointments, setAppointments] = useState([]);
  const [paginated, setPaginated] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState({ otp: "", hash: "", email: "", action: "" });
  const [appointmentID, setAppointmentID] = useState("");
  const [change, setChange] = useState(true);
  const [startDate, setStartDate] = useState(new Map());
  const [compareDate, setCompareDate] = useState(new Map());
  const [page, setPage] = useState(0);
  const [appointmentIT, setAppointmentIT] = useState([]);
  const [teacher_id_map, SetTeacher_id_map] = useState({});
  const [sem, setSem] = useState("");
  const [dept_name, setdept_name] = useState("");
  let serial_no = 0;

  const [previewPDF, setPreviewPDF] = useState<any>({
    index: null,
    data: [],
  });
  const uploadRef = useRef();

  // eslint-disable-next-line
  // eslint-disable-next-line
  const [dept, setdept] = useState([1, 2]);
  const [selectedDate, setSelectedDate] = useState(
    new Date("2014-08-18T21:11:54")
  );
  // eslint-disable-next-line
  const [SemesterFilter, setSemesterFilter] = useState([]);

  const deptList = [
    "Computer Engineering",
    "Information Technology",
    "Electronics and Telecommunication Engineering",
    "Artificial Intelligence and Data Science",
  ];
  const SemesterList = [1, 2, 3, 4, 5, 6, 7, 8];
  // OTP
  const handleOpenOTP = async (_id) => {
    setOpen(true);
    setAppointmentID(_id);
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/otp/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: "examsoftware.qp@gmail.com", id: _id }),
    });
    const { email, hash } = await res.json();

    setOtp((prevOtp) => {
      return { ...prevOtp, hash, email };
    });
  };
  const handleCloseOTP = () => {
    setOpen(false);
  };

  const History = useHistory();

  // fetch appointments from our database
  const fetchAppointments = async () => {
    const apps1 = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/appointments`
    );
    let data = await apps1.data;
    console.log(data, "190");
    if (sessionStorage.getItem("role") === "teacher") {
      data = data.filter((appointment) => {
        console.log(appointment.teacher.email, sessionStorage.getItem("email"));
        return appointment.teacher.email === sessionStorage.getItem("email");
      });
    }
    setAppointments(data);
    setRows(data);
    console.log(data);
  };

  console.log(rows);

  //Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const emptyRows = 10 - Math.min(10, rows.length - page * 10);

  // Use Effects
  useEffect(() => {
    const token = sessionStorage.getItem("login");
    if (token === null) {
      History.push("/login?redirect=reports");
    }
    fetchAppointments();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (otp.otp !== "" && open) {
      if (otp.action === "download") {
        downloadPaper();
      } else if (otp.action === "delete") {
        deletePaper();
      }
    }
    // eslint-disable-next-line
  }, [otp, open]);

  // upload PDF
  const re = RegExp(
    "^QP_[a-zA-Z]+_[a-zA-Z]+_[a-zA-Z0-9]+_[a-zA-Z]+_SET_[123].pdf$"
  );

  const uploadPDF = async (file) => {
    return new Promise((resolve, reject) => {
      const appointmentRef = storage.ref().child("QP").child(appointmentID);
      let subjectname = "";
      for (let i = 0; i < appointments.length; i++) {
        if (appointments[i]._id === appointmentID) {
          subjectname += appointments[i].subject;
        }
      }
      subjectname = subjectname.replace(/\s+/g, "");
      let ss = "";
      for (let i = 14; i < file.name.length; i++) {
        if (file.name[i] !== "_") {
          ss += file.name[i];
        } else {
          break;
        }
      }
      if (ss !== subjectname) {
        alert("Wrong Subject !!! ");
        window.location.reload();
      }
      if (re.test(file.name)) {
        appointmentRef
          .child(file.name)
          .put(file)
          .then(async (_snapshot) => {
            const downloadURL = await _snapshot.ref.getDownloadURL();
            resolve(downloadURL);
          })
          .catch((err) => reject(err));
      } else {
        alert(
          `Please upload file according to the format mentioned : QP_Year_SubjectName_Set_SetNo`
        );
        window.location.reload();
      }
    });
  };

  const readAllFiles = async (allFiles) => {
    const results = await Promise.all(
      allFiles.map(async (file, index) => {
        // if (file.type === "application/json") {
        //   const fileContents = await readFileChosen(file);
        //   return {
        //     paper_num: index + 1,
        //     paperType: "json",
        //     question_format: fileContents,
        //   };
        // } else {
        const pdfURL = await uploadPDF(file);
        return { paper_num: index + 1, paperType: "pdf", pdfURL };
      })
    );
    return results;
  };

  const handlePaperUpload = async (e) => {
    const NUM_PAPERS = 3;
    const files = e.target.files;

    if (Array.from(files).length !== NUM_PAPERS) {
      e.preventDefault();
      e.target.value = "";
      alert(`You need to upload ${NUM_PAPERS} papers!`);
      return;
    }

    let data;
    try {
      data = await readAllFiles([...files]);
    } catch (error) {
      console.log(error);
    }
    setPreviewPDF({ index: 0, data });
    e.target.value = "";
  };

  const downloadPDF = (fileName, href) => {
    fetch(href, {
      method: "GET",
    })
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = fileName; // the filename you want
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };

  const verifyOTP = async () => {
    const resVerified = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/otp/verify`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(otp),
      }
    );
    const verified = await resVerified.json();
    return verified;
  };

  const downloadPaper = async () => {
    const verified = await verifyOTP();
    if (verified.msg === "Verified") {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/paper?id=${appointmentID}`)
        .then((res) => res.json())
        .then(
          ({ appointment: { subject, semester, department }, questions }) => {
            const fileName = `${subject}-${department} ${semester}.pdf`;

            downloadPDF(fileName, questions.pdfURL);

            setOpen(false);
          }
        );
    } else {
      alert(verified.err);
    }
    setOtp({ otp: "", hash: "", email: "", action: "" });
  };

  const uploadPaper = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/paper`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ appointmentID, data: previewPDF.data }),
    })
      .then((res) => res.json())
      .then((data) => {
        // fetchAppointments();
        window.location.reload();
        setPreviewPDF({
          index: null,
          data: [],
        });
      })
      .catch((err) => console.log(err));
  };

  const deletePaper = async () => {
    const verified = await verifyOTP();
    if (verified.msg === "Verified") {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/paper?id=${appointmentID}`, {
        method: "DELETE",
      })
        .then(() => {
          window.location.reload();
        })
        .catch((err) => console.log(err));
    } else {
      alert(verified.err);
    }
    setOtp({ otp: "", hash: "", email: "", action: "" });
  };

  const signUpConvenorsFromIT = async () => {
    setLoading(true);
    const options = {
      url: "https://easbackend.kjsieit.com/user/login",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      withCredentials: true,
      data: {
        email: "epus.tech@somaiya.edu",
        password: "Shyamal_01",
      },
    };

    axios(options)
      .then(async (response) => {
        if (response.status === 200) {
          let role = response.data.user.role;
          let appointment_IT = await fetch(
            `https://easbackend.kjsieit.com/appointments?page=1`,
            {
              method: "GET",
              credentials: "include",
            }
          );
          //  const cnt = Math.floor(appointment_IT.count / 50) + 1;
          let data1 = await appointment_IT.json();
          console.log(data1.results);
          let appointmentData = data1.results;
          const count = Math.floor(data1.count / 50) + 1;
          for (let j = 2; j <= count; j++) {
            appointment_IT = await fetch(
              `https://easbackend.kjsieit.com/appointments?page=${j}`,
              {
                method: "GET",
                credentials: "include",
              }
            );
            data1 = await appointment_IT.json();
            appointmentData = appointmentData.concat(data1.results);
          }
          appointmentData = appointmentData.filter(
            (appts) =>
              appts.Exam_type === "Theory" &&
              appts.Summer_winter === "Summer" &&
              appts.Year === 2022
          );
          const ConvenerSet = new Set();

          for (let i = 0; i < appointmentData.length; i++) {
            if (!ConvenerSet.has(appointmentData[i].Internal_1.Name)) {
              let authSignup = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/auth/signup`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    name: appointmentData[i].Internal_1.Name,
                    email: appointmentData[i].Internal_1.email,
                    password: "12345",
                  }),
                }
              );
              ConvenerSet.add(appointmentData[i].Internal_1.Name);
            }
          }

          let data3 = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/getTeachers`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const teacher_id_map = await data3.json();
          for (let i = 0; i < appointmentData.length; i++) {
            let id = teacher_id_map[appointmentData[i].Internal_1.Name];
            const AppSet = await fetch(
              `${process.env.REACT_APP_BACKEND_URL}/appointments`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  level: "Undergrad",
                  year: appointmentData[i].Year,
                  department: appointmentData[i].Department,
                  semester: appointmentData[i].semester,
                  subject: appointmentData[i].Course,
                  subjectCode: appointmentData[i].Subject_code,
                  syllabusRevision: "Autonomous 2020",
                  examdate: null,
                  teacher: id,
                  submitted: false,
                }),
              }
            );
          }
          setLoading(false);
          alert("Conveners have been signed up");
          window.location.reload();
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error);
        }
        alert("An error Occured");
        setLoading(false);
      });
  };
  const handleDeptFilter = async (e) => {
    await setdept_name(e.target.value);
    let filtered;
    filtered = appointments.filter((app) =>
      app.department.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setRows([...filtered]);
    console.log(dept_name);
  };

  const handleSemFilter = async (e) => {
    await setSem(e.target.value.toString());
    let filtered;
    if (dept_name === "") {
      alert(`Department not selected`);
      filtered = appointments.filter((app) =>
        app.semester
          .toString()
          .toLowerCase()
          .includes(e.target.value.toString().toLowerCase())
      );
    } else {
      filtered = appointments.filter(
        (app) =>
          app.department.toLowerCase().includes(dept_name.toLowerCase()) &&
          app.semester
            .toString()
            .toLowerCase()
            .includes(e.target.value.toString().toLowerCase())
      );
    }
    setRows([...filtered]);
  };

  const handleChangeDate = (date, _id) => {
    alert(`Are you sure you want to change the date ?`);
    //@ts-ignore
    setSelectedDate(date);
    //post request
    fetch(`${process.env.REACT_APP_BACKEND_URL}/timetable`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: _id, new_date: date }),
    })
      .then((res) => res.json())
      .then((data) => console.log(data));
    window.location.reload();
  };

  const goToPanel = () => {
    History.push("/panel");
  };
  const deleteAppointments = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/deleteAppointments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((res) => res.json())
      .then((data) => console.log("appointments deleted"));
    alert(`Appointments deleted`);
    window.location.reload();
  };

  return (
    <Container maxWidth="lg">
      <GetOTP open={open} handleClose={handleCloseOTP} handleOTP={setOtp} />
      {sessionStorage.getItem("role") && (
        <Typography
          variant="h4"
          style={{
            marginTop: "2vh",
            textAlign: "center",
            textDecoration: "underline",
            fontWeight: "bold",
          }}
        >
          {sessionStorage.getItem("role") === "teacher"
            ? "Convenor"
            : sessionStorage.getItem("role") === "admin"
            ? "Exam Section Admin"
            : "Super Admin"}{" "}
          View
        </Typography>
      )}
      {sessionStorage.getItem("role") && (
        <Typography
          variant="h5"
          style={{ marginTop: "1vh", textAlign: "left", fontWeight: "bold" }}
        >
          {sessionStorage.getItem("role") === "teacher"
            ? "Name : " + sessionStorage.getItem("name")
            : sessionStorage.getItem("role") === "admin"
            ? "Name : " + sessionStorage.getItem("name")
            : "Name : " + sessionStorage.getItem("name")}{" "}
        </Typography>
      )}
      {sessionStorage.getItem("role") && (
        <Typography
          variant="h5"
          style={{ textAlign: "left", fontWeight: "bold", marginTop: "1vh" }}
        >
          {sessionStorage.getItem("role") === "teacher"
            ? "Email : " + sessionStorage.getItem("email")
            : sessionStorage.getItem("role") === "admin"
            ? "Email : " + sessionStorage.getItem("email")
            : "Email : " + sessionStorage.getItem("email")}{" "}
        </Typography>
      )}
      <Modal
        open={loading}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.circularProgress}>
          <CircularProgress variant="indeterminate" />
        </div>
      </Modal>
      {sessionStorage.getItem("role") && (
        <Typography
          variant="h5"
          style={{
            marginTop: "2vh",
            textAlign: "left",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          {sessionStorage.getItem("role") === "teacher"
            ? "Instructions : "
            : sessionStorage.getItem("role") === "admin"
            ? ""
            : ""}
          {""}
        </Typography>
      )}
      {sessionStorage.getItem("role") && (
        <Typography variant="h5" style={{ textAlign: "left" }}>
          {sessionStorage.getItem("role") === "teacher"
            ? "Upload 3 papers in PDF format following the convention : QP_Year_Department_Semester_SubjectName_SET_SetNo"
            : sessionStorage.getItem("role") === "admin"
            ? " "
            : " "}{" "}
        </Typography>
      )}
      {sessionStorage.getItem("role") && (
        <Typography
          variant="h5"
          style={{ marginTop: "1vh", textAlign: "left", fontWeight: "bold" }}
        >
          {sessionStorage.getItem("role") === "teacher"
            ? "Example : QP_FY_BS_Sem2_EngineeringMathematicsII_SET_1"
            : sessionStorage.getItem("role") === "admin"
            ? " "
            : " "}{" "}
        </Typography>
      )}
      <br></br>
      {sessionStorage.getItem("role") && (
        <Typography variant="h6" style={{ textAlign: "left", color: "red" }}>
          {sessionStorage.getItem("role") === "teacher"
            ? " Maximum file size is 5MB per file"
            : sessionStorage.getItem("role") === "admin"
            ? " "
            : " "}{" "}
        </Typography>
      )}
      {sessionStorage.getItem("role") && (
        <Typography variant="h6" style={{ textAlign: "left", color: "red" }}>
          {sessionStorage.getItem("role") === "teacher"
            ? " * Please Note : Papers once uploaded cannot be changed"
            : sessionStorage.getItem("role") === "admin"
            ? " "
            : " "}{" "}
        </Typography>
      )}

      <br></br>
      {sessionStorage.getItem("role") === "admin" && (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ margin: "5px", width: "200px" }}>
            <FormControl variant="filled" fullWidth={true}>
              <InputLabel id="demo-simple-select-outlined-label">
                Department
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Department"
                value={dept_name}
                onChange={(e) => {
                  handleDeptFilter(e);
                }}
              >
                {deptList.map((o) => (
                  <MenuItem value={o}>{o}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ margin: "5px", width: "200px" }}>
            <FormControl variant="filled" fullWidth={true}>
              <InputLabel id="demo-simple-select-filled-label">
                Semester
              </InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-filled-outlined"
                label="Sem"
                value={sem}
                onChange={(e) => {
                  handleSemFilter(e);
                }}
              >
                {SemesterList.map((o) => (
                  <MenuItem value={o}>{o}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <IconButton
            onClick={() => {
              setdept_name("");
              setSem("");
              setRows([...appointments]);
            }}
          >
            <CancelIcon />
          </IconButton>
        </div>
      )}

      {sessionStorage.getItem("role") !== "teacher" && (
        <div style={{ textAlign: "right" }}>
          <Tooltip title="View emails and logins of convenors and add/delete admins">
            <Button
              style={{
                backgroundColor: "#911717",
                color: "white",
                marginLeft: "50px",
              }}
              variant="contained"
              onClick={() => goToPanel()}
            >
              Manage Admins
            </Button>
          </Tooltip>
          <Tooltip title="Once you fetch convenor appointments convenor logins will be enabled">
            <Button
              style={{
                backgroundColor: "#911717",
                color: "white",
                marginLeft: "50px",
              }}
              variant="contained"
              onClick={signUpConvenorsFromIT}
            >
              Fetch Convenor details
            </Button>
          </Tooltip>
        </div>
      )}
      <br></br>
      <TableContainer component={Paper}>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {[
                { key: "serial_no", display: "Sr No." },
                { key: "level", display: "Level" },
                { key: "department", display: "Department" },
                //{ key: "examTitle", display: "Exam Title" },
                { key: "semester", display: "Semester" },
                { key: "subjectCode", display: "Subject Code" },
                { key: "subject", display: "Subject Name" },
                { key: "syllabusRevision", display: "Syllabus Revision" },
                { key: "questionPaperCode", display: "Exam Date" },
                { key: "teacher", display: "Convener" },
                { key: "submitted", display: "Paper upload status" },
              ].map(({ key, display }, index) => {
                return (
                  <StyledTableCell
                    align="center"
                    key={index}
                    style={{ fontWeight: "bold" }}
                  >
                    <StyledTableSortLabel
                      active={orderBy === key}
                      //@ts-ignore
                      // direction={orderBy === key ? order : "asc"}
                      // onClick={(e) => {
                      //   handleRequestSort(e, key);
                      // }}
                    >
                      {display}
                    </StyledTableSortLabel>
                  </StyledTableCell>
                );
              })}
              {sessionStorage.getItem("role") !== "admin" && (
                <StyledTableCell />
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * 10, page * 10 + 10)
              .map(
                (
                  {
                    level,
                    year,
                    department,
                    semester,
                    subject,
                    subjectCode,
                    syllabusRevision,
                    examdate,
                    teacher,
                    submitted,
                    _id,
                  },
                  index
                ) => (
                  <TableRow hover key={index}>
                    <StyledTableCell align="center">
                      {(serial_no += 1)}
                    </StyledTableCell>
                    <StyledTableCell align="left">{level}</StyledTableCell>
                    <StyledTableCell align="left">{department}</StyledTableCell>
                    <StyledTableCell align="center">
                      {romanize(semester)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {subjectCode}
                    </StyledTableCell>
                    <StyledTableCell align="left">{subject}</StyledTableCell>

                    <StyledTableCell align="left">
                      {syllabusRevision}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ position: "static", zIndex: 999, width: "20%" }}
                    >
                      {sessionStorage.getItem("role") === "admin" && (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container justifyContent="space-around">
                            <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              value={examdate}
                              onChange={(date) => handleChangeDate(date, _id)}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      )}
                      {/* {sessionStorage.getItem("role") === "teacher" && (
                        <DatePicker
                          selected={null}
                          locale="pt-BR"
                          dateFormat="dd/MM/yyyy"
                        />
                      )} */}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {teacher.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {submitted ? <CheckCircleIcon /> : <CancelIcon />}
                    </StyledTableCell>

                    {sessionStorage.getItem("role") === "teacher" && (
                      <StyledTableCell align="right">
                        <input
                          ref={uploadRef}
                          style={{ display: "none" }}
                          id="contained-button-file"
                          multiple
                          type="file"
                          accept="application/pdf, application/json"
                          onChange={handlePaperUpload}
                        />
                        <IconButton
                          disabled={submitted}
                          onClick={() => {
                            // handleOpenOTP(_id);
                            setAppointmentID(_id);
                            //@ts-ignore
                            uploadRef?.current?.click();
                          }}
                        >
                          <CloudUploadIcon />
                        </IconButton>
                      </StyledTableCell>
                    )}
                    {sessionStorage.getItem("role") === "superAdmin" && (
                      <StyledTableCell align="right">
                        <IconButton
                          disabled={!submitted}
                          onClick={() => {
                            handleOpenOTP(_id);
                            setOtp((prevState) => {
                              return { ...prevState, action: "download" };
                            });
                          }}
                        >
                          <GetAppIcon />
                        </IconButton>
                      </StyledTableCell>
                    )}
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        rowsPerPage={10}
        component="div"
        count={200}
        page={page}
        onPageChange={handleChangePage}
      />
      {previewPDF.data &&
        previewPDF.data.map((paper, index) => {
          return (
            <Modal
              key={index}
              open={previewPDF.index === index}
              onClose={() =>
                setPreviewPDF({ index: null, data: previewPDF.data })
              }
            >
              <Card key={index}>
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {paper.paperType === "pdf" ? (
                      <iframe
                        title={paper.index}
                        style={{ width: "100%", height: "80vh" }}
                        src={paper.pdfURL}
                      />
                    ) : (
                      <RenderInstance paper={paper} />
                    )}
                    <div style={{ display: "flex", alignSelf: "flex-start" }}>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={previewPDF.index === 0}
                        onClick={() =>
                          setPreviewPDF(({ index, data }) => {
                            // @ts-ignore
                            const newIndex = index - 1;
                            return { data, index: newIndex };
                          })
                        }
                      >
                        Previous
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={previewPDF.index === 2}
                        style={{ marginLeft: "1rem" }}
                        onClick={() =>
                          setPreviewPDF(({ index, data }) => {
                            // @ts-ignore
                            const newIndex = index + 1;
                            return { data, index: newIndex };
                          })
                        }
                      >
                        Next
                      </Button>
                      {previewPDF.index === 2 && (
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ marginLeft: "1rem" }}
                          onClick={() => {
                            window.confirm(
                              "Are you sure you want to submit?"
                            ) && uploadPaper();
                          }}
                        >
                          Submit
                        </Button>
                      )}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Modal>
          );
        })}
    </Container>
  );
};

export default AdminConvener;
