import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useEffect, useState } from "react";
//import { useStateValue } from "../StateProvider";
import { useHistory } from "react-router-dom";
import { Card, CardContent } from "@material-ui/core";
//import { useQuery } from "./utils/hooks";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(8),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    // backgroundColor: theme.palette.primary.dark,
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
    backgroundColor: "#911717",
    "&:hover": {
      backgroundColor: "#911717",
      boxShadow: "1px 2px 5px black",
    },
  },
}));

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#911717",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#911717",
      },
    },
  },
})(TextField);

const Signup = () => {
  const history = useHistory();

  useEffect(() => {
    const t = sessionStorage.getItem("role");
    if (t === "admin" || t === "superAdmin") {
      history.push("/signup");
    } else {
      history.push("/login");
    }
    // eslint-disable-next-line
  }, []);

  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/signup_admin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: name, email: email, password: password }),
    })
      .then((res) => res.json())
      .then((data) => console.log(data));
    alert(`New Admin added`);
    history.push("/reports");
  };

  return (
    <Container component="main" maxWidth="xs">
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Avatar style={{ fill: "black" }} className={classes.avatar}></Avatar>
          <Typography component="h1" variant="h5">
            Create New Admin
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <CssTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <CssTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <CssTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              SIGNUP
            </Button>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};
export default Signup;
