import { useEffect, useState } from "react";
import { Tabs, Tab, AppBar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import { Button } from "@material-ui/core";

const Panel = () => {
  const history = useHistory();

  useEffect(() => {
    const t = sessionStorage.getItem("role");
    if (t === "admin" || t === "superAdmin") {
      history.push("/panel");
    } else {
      history.push("/login");
    }

    getTeachers();
    getAdmins();
    // eslint-disable-next-line
  }, []);

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

  const [teachers, setTeachers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [value, setValue] = useState(0);
  const [appointments, setAppointments] = useState([]);

  const classes = useStyles();

  //@ts-ignore
  function createData(name, email) {
    return { name, email };
  }

  const getAdmins = async () => {
    try {
      const admin_data = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/getAdmins`
      );
      let aids = await admin_data.data;
      let admin_array = [];
      for (let i in aids) {
        admin_array.push({ name: i, email: aids[i] });
      }
      setAdmins(admin_array);
    } catch (err) {
      console.error(err.message);
    }
  };
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/appointments`)
      .then((res) => res.json())
      .then((data) => {
        setAppointments(data);
        // setrunf(true)
      });
  }, []);

  const getTeachers = async () => {
    try {
      const appointment_data = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/appointments`
      );
      let apps = appointment_data.data;
      let teachers_obj = {};
      for (let i in apps) {
        if (!teachers_obj[apps[i].teacher.name]) {
          teachers_obj[apps[i].teacher.name] = apps[i].teacher.email;
        }
      }
      console.log(teachers_obj);
      let teachers_array = [];
      for (let i in teachers_obj) {
        teachers_array.push({ name: i, email: teachers_obj[i] });
      }
      setTeachers(teachers_array);
    } catch (err) {
      console.error(err.message);
    }
  };

  function TabPanel(props) {
    const { children, value, index } = props;
    return <div>{value === index && <div>{children}</div>}</div>;
  }

  const handleTabs = (e, val) => {
    setValue(val);
  };

  const handleDelete = (name) => {
    alert(
      `Are you sure you want to delete the admin . All records will be lost`
    );
    console.log(name);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/deleteAdmins`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: name }),
    });
    window.location.reload();
    alert(`admin deleted successfully`);
  };

  const createNewAdmin = () => {
    // eslint-disable-next-line
    history.push("/signup");
  };

  return (
    <div>
      <Button
        style={{
          backgroundColor: "#911717",
          color: "white",
          marginTop: 20,
          marginLeft: 20,
        }}
        variant="contained"
        onClick={() => createNewAdmin()}
      >
        Add Admin
      </Button>
      <br></br>
      <br></br>
      <AppBar position="static">
        <Tabs value={value} onChange={handleTabs}>
          <Tab label="Convenor" />
          <Tab label="Admin" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Name</TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Email
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teachers.length !== 0 &&
                  teachers.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </TabPanel>
      <TabPanel value={value} index={1}>
        {
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Name</TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Email
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {admins.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell>
                      {sessionStorage.getItem("email") !== row.email && (
                        <DeleteIcon
                          onClick={() => {
                            handleDelete(row.name);
                          }}
                        ></DeleteIcon>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </TabPanel>
    </div>
  );
};
export default Panel;
