import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDGN9DF1IOla94Yw2QPB6sgSRdKNWxxIxY",
  authDomain: "epus-4b8b9.firebaseapp.com",
  projectId: "epus-4b8b9",
  storageBucket: "epus-4b8b9.appspot.com",
  messagingSenderId: "609472372625",
  appId: "1:609472372625:web:045c616bc507a287770430",
  measurementId: "G-C2D85202HZ",
});

const storage = firebaseApp.storage();

export { storage };
